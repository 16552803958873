@import url('https://api.fontshare.com/v2/css?f[]=satoshi@700,300,401,400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script&family=Roboto+Mono:wght@100;200;400;500&family=Roboto:wght@100;300&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

html{
  scroll-behavior: smooth;
  /* background-color: #000000; */
  background-image: radial-gradient( circle farthest-corner at 10% 20%,  rgba(100,43,115,1) 0%, rgba(4,0,4,1) 90% );
}
.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Hide scrollbar for IE, Edge and Firefox */
  .hide-scrollbar {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  ::-webkit-scrollbar {
    width: 5px;
  }
  

 ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   

  ::-webkit-scrollbar-thumb {
    background: #888; 
  }
  

::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }


  .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
    height: 3px;
  }
  
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: rgba(241, 240, 241, 0.425);
  }
  
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #39D2C0;
  }
  
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .video-player video::-webkit-media-controls-download-button {
    display: none;
  }

  .trapezoid {
    border-top: 60px solid #8F95A1;
    border-left: 25px solid transparent;
    border-right: 25px solid transparent;
    height: 0;
    
    /* width: 125px; */
  }
  .trapezium {
    width: 200px; /* Adjust as needed */
    height: 0;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    border-bottom: 100px solid;
    background: linear-gradient(135deg, #3498db, #e74c3c); /* Gradient colors */
  }
  .flip-card {
    background-color: transparent;
    /* width: 600px; */
    height: 300px;
    border: 1px solid #f1f1f1;
    perspective: 1000px; /* Remove this if you don't want the 3D effect */
  }
  
  /* This container is needed to position the front and back side */
  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
  }
  
  /* Do an horizontal flip when you move the mouse over the flip box container */
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }
  
  /* Position the front and back side */
  .flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
  }
  
  /* Style the front side (fallback if image is missing) */
  .flip-card-front {
    background-color: #bbb;
    color: black;
  }
  
  /* Style the back side */
  .flip-card-back {
    background-color: #FF7622;
    color: white;
    transform: rotateY(180deg);
  }
  .slide-from-left {
    position: relative;
    left: -100%; /* Start the div off the screen */
    transition: left 0.5s ease; /* Use ease or other transition functions */
  }
  
  .slide-from-left.active {
    left: 0; /* Slide the div in from the left */
  }
  .paraslide {
    animation-duration: 3s;
    animation-name: slidein;
  }
  
  
  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }
  
    75% {
      font-size: 300%;
      margin-left: 25%;
      width: 150%;
    }
  
    to {
      margin-left: 0%;
      width: 100%;
    }
  }
  
  .progress-bar {
    position: fixed;
    z-index: 1000;
    top: 200;
    left: 0;
    right: 0;
    height: 5px;
    background: #39D2C0;
    transform-origin: 0%;
  }

  .child {

    position: absolute;
    /* top: 50%; */
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
  .childd{

    position: absolute;
    /* top: 50%; */
    right: 0;
    bottom: 0;
    z-index: 1000;
  }

  .ig {
    font-size: 72px;
    background: -webkit-linear-gradient(#feda75 ,  #fa7e1e, #d62976, #962fbf, #4f5bd5);
    /* -webkit-background-clip: text; */
    -webkit-text-fill-color: transparent;
    border-radius: 7px;
  }
